import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckCircle, Loader2 } from 'lucide-react';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  increment, 
  getFirestore, 
  collection, 
  addDoc,
  serverTimestamp
} from 'firebase/firestore';
import useAuth from '../hooks/useAuth';

const CreditsSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isProcessing, setIsProcessing] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const processPayment = async () => {
      if (!sessionId || !user) {
        navigate('/credits');
        return;
      }

      try {
        const db = getFirestore();

        // Get the checkout session
        const sessionRef = doc(db, 'customers', user.uid, 'checkout_sessions', sessionId);
        const sessionDoc = await getDoc(sessionRef);
        
        if (!sessionDoc.exists()) {
          throw new Error('Checkout session not found');
        }

        const session = sessionDoc.data();
        const creditsToAdd = parseInt(session.metadata?.credits || '0');

        if (isNaN(creditsToAdd) || creditsToAdd <= 0) {
          throw new Error('Invalid credit amount');
        }

        // Update user credits
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
          // Create user document if it doesn't exist
          await updateDoc(userRef, {
            email: user.email,
            username: user.username,
            credits: creditsToAdd,
            createdAt: serverTimestamp(),
            lastUpdated: serverTimestamp()
          });
        } else {
          // Update existing user document
          await updateDoc(userRef, {
            credits: increment(creditsToAdd),
            lastUpdated: serverTimestamp()
          });
        }

        // Create credit transaction record
        await addDoc(collection(db, 'creditTransactions'), {
          userId: user.uid,
          amount: creditsToAdd,
          type: 'add',
          source: 'purchase',
          sessionId,
          timestamp: serverTimestamp(),
          metadata: {
            email: user.email,
            checkoutSessionId: sessionId
          }
        });

        setIsProcessing(false);
        
        // Redirect after success
        setTimeout(() => {
          navigate('/credits');
        }, 2000);
      } catch (err) {
        console.error('Error processing payment:', err);
        setError(err instanceof Error ? err.message : 'Failed to process payment');
        setIsProcessing(false);
      }
    };

    processPayment();
  }, [sessionId, user, navigate]);

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
          <div className="text-red-500 mb-4">
            <svg className="w-16 h-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            Payment Processing Error
          </h2>
          <p className="text-gray-600 mb-4">{error}</p>
          <button
            onClick={() => navigate('/credits')}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
          >
            Return to Credits Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
        {isProcessing ? (
          <>
            <Loader2 className="w-16 h-16 text-indigo-500 animate-spin mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Processing Payment
            </h2>
            <p className="text-gray-600">
              Please wait while we verify your payment and add credits to your account...
            </p>
          </>
        ) : (
          <>
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Payment Successful!
            </h2>
            <p className="text-gray-600">
              Your credits have been added to your account. Redirecting you back...
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default CreditsSuccessPage;