import { useState } from 'react';
import { collection, doc, addDoc, onSnapshot, getFirestore, getDoc, setDoc } from 'firebase/firestore';
import useAuth from './useAuth';

interface CheckoutOptions {
  priceId: string;
  credits: number;
}

interface UseStripeReturn {
  handleCheckout: (options: CheckoutOptions) => Promise<{ success: boolean; error?: string }>;
  isLoading: boolean;
  error: string | null;
}

const useStripe = (): UseStripeReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();
  const db = getFirestore();

  const ensureCustomerExists = async () => {
    if (!user) return;

    const customerRef = doc(db, 'customers', user.uid);
    const customerDoc = await getDoc(customerRef);

    if (!customerDoc.exists()) {
      // Create customer document if it doesn't exist
      await setDoc(customerRef, {
        email: user.email,
        stripeCustomerId: null, // Will be set by the extension
        createdAt: new Date(),
        updatedAt: new Date()
      });
    }
  };

  const handleCheckout = async ({ priceId, credits }: CheckoutOptions) => {
    if (!user) {
      return { success: false, error: 'User not authenticated' };
    }

    try {
      setIsLoading(true);
      setError(null);

      // Ensure customer exists in Firestore
      await ensureCustomerExists();

      // Create checkout session document
      const checkoutSessionRef = collection(db, 'customers', user.uid, 'checkout_sessions');
      const checkoutSession = await addDoc(checkoutSessionRef, {
        mode: 'payment',
        price: priceId,
        success_url: `${window.location.origin}/#/credits/success?session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${window.location.origin}/#/credits?error=cancelled`,
        metadata: {
          credits: credits.toString(),
          userId: user.uid,
          type: 'credit_purchase',
          email: user.email
        },
        allow_promotion_codes: true,
        tax_id_collection: { enabled: true },
        collect_shipping_address: false,
        payment_method_types: ['card']
      });

      // Listen for the session URL
      return new Promise((resolve, reject) => {
        const unsubscribe = onSnapshot(
          checkoutSession,
          (snap) => {
            const { error, url } = snap.data() || {};

            if (error) {
              unsubscribe();
              setIsLoading(false);
              reject(new Error(error.message));
            }

            if (url) {
              unsubscribe();
              window.location.assign(url);
              resolve({ success: true });
            }
          },
          (error) => {
            console.error('Checkout session error:', error);
            unsubscribe();
            setIsLoading(false);
            reject(error);
          }
        );

        // Timeout after 10 seconds
        setTimeout(() => {
          unsubscribe();
          setIsLoading(false);
          reject(new Error('Checkout session creation timed out'));
        }, 10000);
      });

    } catch (err) {
      console.error('Checkout error:', err);
      const errorMessage = err instanceof Error ? err.message : 'Failed to initiate checkout';
      setError(errorMessage);
      return { success: false, error: errorMessage };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCheckout,
    isLoading,
    error,
  };
};

export default useStripe;