export const CREDIT_PACKAGES = [
  {
    name: 'Starter',
    priceId: 'price_1QK3gQGpqdcCYOxgkWCpTeWp',
    credits: 10,
    outputs: 5,
    price: 8,
    description: 'Perfect for trying out the service'
  },
  {
    name: 'Basic',
    priceId: 'price_1QK3icGpqdcCYOxgNbIVosuA',
    credits: 20,
    outputs: 10,
    price: 15,
    description: 'Great for occasional use'
  },
  {
    name: 'Pro',
    priceId: 'price_1QK3llGpqdcCYOxgfEAQFi7e',
    credits: 40,
    outputs: 20,
    price: 30,
    popular: true,
    description: 'Most popular choice for regular users'
  },
  {
    name: 'Business',
    priceId: 'price_1QK3nPGpqdcCYOxgt7YhRt5w',
    credits: 80,
    outputs: 40,
    price: 60,
    description: 'Ideal for business users'
  },
  {
    name: 'Enterprise',
    priceId: 'price_1QK3orGpqdcCYOxg89994aqE',
    credits: 160,
    outputs: 80,
    price: 100,
    description: 'Best value for high-volume users'
  }
] as const;